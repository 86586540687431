
$top-loader-height: 4px;

.top-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: $top-loader-height;
  background-color: currentColor;
  z-index: 50;

  &::before {
    position: absolute;
    width: 20%;
    display: block;
    content: "";
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    animation: top-loader-child-1-animation 2.5s infinite linear;
  }

  &::after {
    position: absolute;
    width: 20%;
    display: block;
    content: "";
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    animation: top-loader-child-2-animation 2.5s infinite linear;
  }
}

@keyframes top-loader-child-1-animation {
  from {
    left: 0%;
    width: 0%;
  }
  25% {
    left: 0%;
    width: 60%;
  }
  44% {
    left: 40%;
    width: 70%;
  }
  60% {
    left: 100%;
    width: 80%;
  }
  to {
    left: 100%;
    width: 30%;
  }
}

@keyframes top-loader-child-2-animation {
  from {
    left: 0%;
    width: 0%;
  }
  55% {
    left: 0%;
    width: 0%;
  }
  65% {
    left: 0%;
    width: 25%;
  }
  78% {
    left: 35%;
    width: 18%;
  }
  to {
    left: 100%;
    width: 5%;
  }
}