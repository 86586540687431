@import "./responsive-mixins.scss";

.collapsible {

  
  margin: 0 -1rem;
  padding: 0 1rem;
  width: calc(100% + 2rem);
  @include mobile-view {
    margin: 0 0;
    padding: 0 0;
    width: 100%;
  }


  &.collapsed {
    max-height: 0px;
  }

  &.overflow-y-hidden {
    // overflow-x: overlay;
    overflow-y: hidden;
    transition: 0.5s max-height;
  }
}

.collapsible-btn {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
  white-space: nowrap;
  
  padding: 0 0.6rem;
  margin: 0 -0.6rem;
}