
@import "variables.scss";

.admin-dashboard {
  // grid-gap: 1em 1em;
  // display: grid;
  // grid-template-columns: repeat(auto-fill,320px);
  display: flex;
  // gap: 0.6em;
  width: auto;
  // margin: 0 -0.5em;

  .admin-dashboard-card {
    
    border: 1px solid #eaeaea;
    // border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
    background-color: $admin-color-toggle;
    font-size: 0.9rem;
    padding: 1.2em 1.8em;
    margin: 0.3em 0.5em 0.6em;

    background-clip: padding-box;


    & > .title {
      color: $admin-color-dark;
      font-size: 1.2em;
      font-weight: 600;
    }
    
    & > .body {
      color: $admin-color-highlight2;
      font-size: 1em;
    }

    .highlight {
      color: $admin-color-highlight;
      font-size: 1.8em;
      font-weight: 600;
      margin-bottom: 0.1em;
    }
  }
}

.admin-dashboard-chart-tooltip {
  background-color: rgba(white, 0.7);
  border: 1px solid #7d9bba;
  border-radius: 3px;
  outline: none;
  padding: 0.3em 0.6em;
}