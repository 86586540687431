@import "../variables.scss";

.admin-toast-container {
  font-size: 0.9em;
  .admin-toast {

    transition: background-color 0.3s ease;
    &.Toastify__toast--default {
      background-color: #2b799f;
    }
  

    &.Toastify__toast--success {
      background-color: #37923d;
    }

    &.Toastify__toast--error {
      background-color: #c03b3b
    }
    color: white !important;
    // border-radius: 0;
    box-shadow: 1px 2px 9px rgba(100, 100, 100, 50%);

  }
}