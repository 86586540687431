
.rdrCalendarWrapper, .rdrDateRangeWrapper {
  .rdrMonthAndYearWrapper {
    margin-top: -0.6em;
  }
  .hidden-component {
    margin: 0;
    padding: 0;
    height: 0;
    overflow-y: hidden;
  }
}