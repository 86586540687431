

.autocomplete-search-container {
  display: flex;
  position: relative;
  width: 100%;
  font-size: 0.9rem;
  z-index: 40;

  & > .prefix, & > .postfix {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1; 
    font-size: 0.9rem;
    // left: 0;
    top: 50%;
    transform: translateY(-50%);
    // left: 0.95rem;
    color:darkgrey; 

    &.postfix {
      right: 0;
    }

    & > .prefix-icon-area {
      position: relative;
      
      & > .search-icon {
        padding: 0 0rem 0 0.8rem;
        font-size: 1rem;
      }
      & > .loader {
        position: absolute;
        right: 0;
        // width: 1rem;
        & > .spinner-border {
          width: 1rem;
          height: 1rem;
          border-width: 0.15rem;
          border-color: darkgrey;
          border-right-color: transparent;
        }
        display: none;
      }

      &.loading {
        & > .search-icon {
          opacity: 0;
        }
        & > .loader {
          display: block;
        }
      }
    }


  }

  & > .autocomplete-search {
    width: 100%;

    .autocomplete-search__value-container {
      padding: 0em;
      .autocomplete-search__input-container, .autocomplete-search__placeholder {
        // margin-left: 2em;
        font-size: 0.9rem;
        cursor: text;
        overflow-x: hidden;
        display: flex;
      }
    }
  
  
    
    .autocomplete-search__indicators {
      // padding-right: 0.9em;
      // margin: 0 -1em;
    }
    .autocomplete-search__group {
      padding-top: 0.3em;
      padding-bottom: 0.1em;
      margin: 0;
    }

    .autocomplete-search__group-heading {
      font-weight: bold;
      text-transform: none;
      // text-align: center;
      // opacity: 0.3;
      // padding:0 ;
      // margin: 0;
    }

    .autocomplete-search__input {
      opacity: 1 !important;
      position: absolute;
    }
    .autocomplete-search__menu {
      margin: 0;
    }
  }

}