

@import "./variables.scss";

.property-history {
  min-height: 200px;
  height: 20vh;
  overflow: auto;
  width: 100%;
  position: relative;
  box-shadow: 2px 2px 7px rgba(grey, 0.7);
  margin: 0.3rem 0;
  background-color: white;
  & > * {
    padding: 0 0rem 3rem 0rem;
    position: absolute;
  }

  .preview-icon {
    color: lightgrey;
    margin-right: -0.5rem;
    transition: 0.3s color;
    &.selected {
      color: $admin-color-highlight2;
    }
  }
}

.property-star {
  transition: 0.3s all;
  &:hover {
    opacity: 0.6;
  }

  &.clickable {
    cursor: pointer;
  }
}

.internal-remarks {
  min-height: 200px;
  height: 20vh;
  overflow: auto;
  width: 100%;
  position: relative;
  box-shadow: 2px 2px 7px rgba(grey, 0.7);
  margin: 0.3rem 0;
}