@import "./variables.scss";

.login-area {
  color: $primary;
  width: 322px;
  min-width: 322px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.4rem 0.5rem;
  row-gap: 0.6rem;
  font-size: 0.85rem;

  .title-area {
    gap: 0.3em;
    display: flex;
    flex-direction: column;
    width: 100%;

    .logo {
      height: 92px;
      max-width: 100%;

      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  
    .title {
      font-size: 1.1em;
    }

    .user {
      width: 100%;
      font-size: 1.1em;
      margin-bottom: 0.6rem;
      .icon {
        margin-right: 0.6rem
      }
    }
  }

  .hr {
    // border-top: 1px solid lightgrey;
    width: calc(100% + 3rem);
    overflow: overlay;
    margin-left: -3rem;
    margin-right: -3rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;

  }

  .button-area {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;

    button {
      font-size: 1em;
      width: 100%;
    }
  }

  .g_id_signin {
    iframe {
      min-width: 100%;
    }
  }
}