
@import "./variables.scss";
@import "../responsive-mixins.scss";

.admin-table-card {
  min-height: 420px;
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
}

.admin-table-scrollable {
  flex: 1;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  // overflow-y: auto;
  // overflow-x: auto;
  // min-height: 600px;
  // & > table {
    
  // }
}

.admin-table {
  width: 100%;
  margin-bottom: 0 !important;
  background-color: white;
  & > :not(:first-child) {
    border-top: 0;
  }

  thead {
    border-bottom: 2px solid currentColor;
  }



  &.sticky {
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;

    thead {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 10;
      th {
        border-bottom: 3px solid currentColor;
      }
    }
  }

  tr {
    transition: 0.3s background-color;
    border-color: rgba(0, 0, 0, 0.3);

    & > td, & > th {
      background-color: white;
      &.sticky {
        // background-color: white;
        position: sticky;
        left: 0;
        z-index: 1;
        border-right: 1px solid rgba(0,0,0,0.3);
      }

      &.highlight-green {
        background-color: #d7ffd7 !important;
      }

      &.highlight-yellow {
        background-color: #fdffd6 !important;
      }


      &.highlight-orange {
        background-color: #fcd5c9 !important;
      }


    }
    
    &:not(.header):nth-child(even) {
      background-color: $admin-color-highlight7;
      & > td, & > th {
        background-color: $admin-color-highlight7;
      }
    }

    &:not(.header):hover {
      background-color: $admin-color-highlight6;
      & > td, & > th {
        background-color: $admin-color-highlight6;
      }
    }

    
    &.selected {
      &, &:hover {
        & > td, & > th {
          background-color: #ffdeb3 !important;
        }
      }
    }
    &.selectable {
      cursor: pointer;
    }

    &.loading {
      background-color: inherit !important;  
      & > td {
        border: none !important;
      }
    }

    th {
      padding: 0.6em 0.3em;
      &:not(:last-child) {
        &:not(.sticky)::after {
          position: absolute;
          content: " ";
          top: 0;
          right: 0;
          height: calc(100% - 10px);
          margin: 5px 0;
          border-right: 1px solid currentColor;
          opacity: 0.1;
          pointer-events: none;
        }
      }
      
      &.sortable {
        cursor: pointer;
        &:hover {
          background-color: $admin-color-highlight6;
        }

        .sort-icon {
          &.inactive {
            color: #cacaca;
          }
        }
      }
    }
    td {
      padding: 0.3em 0.3em;

      
    }
    


    td, th {
      vertical-align: middle;
      border-top: 0;
      white-space: nowrap;
      text-align: center;
      position: relative;
      transition: background-color 0.3s;

      &:first-child {
        padding-left: 0.6em;
      }
      &:last-child {
        padding-right: 0.6em;
      }

      

      &.admin-table-loading, &.admin-table-empty {
        padding: 2em 0;
        font-size: 1.2em;

        @include mobile-view {
          position: fixed;
          width: 100vw;
        }
      }

      &.edit {
        width: 0.0001%;
      }
      &.shrink {
        white-space: nowrap;
        width: 1%;
      }

      // &.td-link {
      //   padding: 0;
      // }

      &.highlighted {
        background-color: $admin-color-highlight5;
      }

      & > .admin-table-td-link {
        text-align: center;
        text-decoration: none;
        // color: inherit;
        // Compensate td padding
        display: block;
        // padding: $td-padding-y $td-padding-x;
        white-space: nowrap;
        text-align: center;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
        }

      }

      
    }
  }
}