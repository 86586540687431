@import "./../variables.scss";

.find-all-query {

}
.find-all-query-pagination {  
  font-size: 1em;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;

  &.hidden {
    display: none;
  }
  
  .pagination-prev, .pagination-next {
    cursor: pointer;
    color: grey;
    padding: 0 0.5rem;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  .pagination-item {
    cursor: pointer;
    color: grey;
    padding: 0 0.3rem;
    transition: 0.3s all;
    
    &:hover, &.selected {
      font-weight: bold;
      color: $info;
    }

    &.selected {
      background-color: rgba($info, 0.1)
    }
  }

  .pagination-text {
    padding: 0 0.5rem;
  }
}
.find-all-query-search-input {
  $padding-x: .6rem;
  
  position: relative;
  & > .input-prefix, & > .input-postfix {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    display: flex;

    .loader {
      width: 1rem;
      height: 1rem;
      border-width: 0.15rem;
      border-color: darkgrey;
      border-right-color: transparent;
    }

    .icon {
      color: darkgrey;
      font-size: 1rem;
      transition: 0.3s color;
      &.active {
        color: $primary;
      }
      &.transparent { 
        opacity: 0;
      }
    }

    &.input-prefix > div {
      display: flex;
      align-items: center;
      padding: 0 0.3rem 0 #{0.3rem + $padding-x};


    }

    &.input-postfix {
      right: 0;
    }

    &.input-postfix > div {
      display: flex;
      align-items: center;
      padding: 0 0 0 0.3rem;

      &:last-child {
        padding-right: #{0.3rem + $padding-x};
      }

      & > .filter-btn, & > .sort-btn {
        display: flex;
        height: 100%;
        align-items: center;
        cursor: pointer;
      }

      & > .sort-by-dropdown {
        position: absolute;
        font-size: 0.95rem;
        min-width: 200px;
        right: 0;
        top: 0;
        background: white;
        margin-top: calc(100% - 0.2em);
        border: 1px solid lightgrey;
        padding: 0.6rem 0rem;

        display: flex;
        flex-direction: column;
        gap: 0.3em;

        & > * {
          padding: 0.3em 2em 0.3em 1em;
          display: flex;
          align-items: center;
          gap: 0.6em;

          &.title {
            font-weight: 600;
            font-size: 0.85em;
            padding-top: 0.2em;
            padding-bottom: 0.2em;
          }
          &.item {
            cursor: pointer;
            font-size: 0.85em;
            &:hover {
              background-color: lightgrey;
            }
            &.disabled {
              pointer-events: none;
              color: lightgrey;
            }
          }
        }
        
      }
      
    }
  }

  & > input {
    font-size: 0.9rem;
  }
}

