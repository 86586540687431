
@import "./variables.scss";

.file-viewer-backdrop {
  z-index: 1030 !important;    // File manager: 1020, File property: 1040, Glboal modal 1050,
  
}

.file-property-backdrop {
  z-index: 1040 !important;    // File manager: 1020, File viewer: 1030, Glboal modal 1050, 
}

.file-viewer {

  display: flex;
  align-items: center;
  justify-content: center;
  

  &:not(.file-property) {
    z-index: 1035;    // File manager: 1025, File property: 1045, Glboal modal 1055
    .modal-dialog {
      max-width: 90%;
    }
  }
  .modal-dialog {

    .modal-header {
      .filename {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0em;
      position: relative;
      min-height: 200px;

      .file-viewer-image img {
        margin: 1.2em 0.3em;
        max-height: 64vh;
        max-width: 100%;
      }
    }

    .modal-footer {
      .btn {
        .icon {
          margin-right: .6em;
        }
      }
    }
  }

  &.file-property {
    z-index: 1045;
    .modal-dialog {
      .modal-body {
        display: block !important;

        .file-property-list {
          font-size: 0.85em;
          margin: 1em 0;
          .item {
            margin: 0.6em 0.6em;
            align-items: center;
            height: 2.4em;
            .key {
              text-align: right;
            }
            .value {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            }
          }
        }
      }
    }
  }

}


// Shared File Viewer Loader

.file-viewer-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 999;
  transition: 0.1s opacity ease-in;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }


  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    &.lds-ring-sm {
      width: 16px;
      height: 16px;

      & > div {
        width: 16px;
        height: 16px;
        border-width: 2px;
        margin: 0px;
      }
    }

    & > div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 4px solid $file-manager-color;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $file-manager-color transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }

  }

}

.file-viewer-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  
    &.collapsed {
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }
  
    &.no-image.collapsed {
      display: none;
    }
  }

}