@import "./variables.scss";


.data-list {

  &.scrollable {
    height: 0px;
    flex-grow: 1;
    overflow-y: overlay;
    margin-bottom: -$admin-container-padding-bottom;
    padding-bottom: $admin-container-padding-bottom + 9rem;
    margin-right: -$admin-container-padding-x;
    padding-right: $admin-container-padding-x !important;
  }

  .data-list-empty {
    font-size: 1.2em;
    padding: 1.6em 0.3em;
    text-align: center;

    & > .icon {
      vertical-align: -0.26em;
      font-size: 1.8em;
      margin-right: 0.5em;
    }
  }

  & > * {
    &.data-list-item {
      border: 1px solid #dedede;
      background-color: $admin-color-toggle;
    }
    &.data-list-item-clickable {
      text-decoration: none;
      color: inherit;
      & > .data-list-item {
        outline: 1px solid #dedede;
        margin-top: 1px;
        margin-left: 1px;
      }
    }

    &.data-list-item, &.data-list-item-clickable > .data-list-item {

      background-color: $admin-color-toggle;
      padding: 0.9rem 2rem 1.2rem;
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      transition: 0.3s background-color;
      position: relative;

      &.clickable {
        cursor: pointer;
        &:hover {
          background-color: #ebedf0;
        }
      }

      &.active {
        cursor: auto;
        background-color: #e3eaf4;
        &:hover {
          background-color: #e3eaf4;
        }
      }

      &:not(:first-child) {
        margin-top: -1px;
      }

      

      .anchor {
        position: absolute;
        top: 0;
      }

      .data-list-item-edit-btn {
        color: darkgrey;
        cursor: pointer;

        &.active {
          color: $primary;
        }
      }

      .data-key {
        margin-right: 0.6rem;
        font-size: 0.8rem;
      }

      & > .data-list-item-title {
        font-size: 1.05rem;
        display: flex;
        // gap: 1rem;
        align-items: center;

        .data-list-item-title-btns {
          display: flex;
          align-items: center;
          margin-left: auto;
          padding-left: 0.6rem;
          gap: 0.9rem;

        }
      }

      & > .data-list-item-body {
        font-size: 0.95rem;
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        & > div {
          display: flex;
          align-items: center;
        }

        & .btns {
          display: flex;
          gap: 0.3rem;
          flex-wrap: wrap;
        }

        
      }
    }  
  }


}

// Data list in modal
.modal {
  .data-list {
    margin-right: -1em;
    padding-right: 1em !important;
    margin-bottom: -1em;
  }
}