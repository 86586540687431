.social-button {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0.5em 0.8em;
  
  & > svg {
    flex: 0;
  }

  & > span {
    flex: 1; 
    font-weight: 600;
    font-size:0.95em;
  }
}