@import "./variables.scss";


.file-manager {
  font-size: 0.85rem;
  display: flex;
  width: 100%;
  height: 100%;
  color: $file-manager-color;
  position: relative;
  outline: none;

  
  ul {
    list-style-type:none;
    inset: auto auto 0px 0px;
    margin: 0;
    padding: 0.2em 0;
    
    & > li {
      margin: 0;
      padding: 0.5em 1.2em;
      cursor: pointer;

      .icon {
        margin-right: .7em;
      }

      &:hover {
        background-color: $file-manager-color-highlight-grey;
      }

      &[data-disabled=true] {
        color: #999;
        cursor: auto;
        pointer-events: none;
      }

      &.choose-button {
        font-weight: bold;
        // border: 1px solid $file-manager-color;
        background-color: $file-manager-color2;
        color: white;
        // transition: 0.3s all;
        &:hover {
          color: $file-manager-color2;
          background-color: $file-manager-color-highlight;
        }
        &[data-disabled=true] {
          opacity: 0.6;
        }
      }
    }
  }

  .file-manager-main {
    flex: 3;
    display: flex;
    flex-direction: column;
    border: 1px solid $file-manager-border-color;

    .file-manager-main-loading {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 1;
      pointer-events: none;
      opacity: 0;

      transition: 0.3s opacity;

      &.active {
        opacity: 1;
        pointer-events: all;
      }
    }

    .file-manager-main-no-file {
      font-size: 1.3em;
      font-weight: lighter;
      text-align: center;
      padding-top: 2em;
    }

    .file-manager-main-dragging {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba($file-manager-color-highlight-light, .8);
      z-index: 1;
      opacity: 0;
      border: 4px dashed;
      pointer-events: none;
      &.active {
        opacity: 1;
      }

      .icon {
        margin-bottom: 0;
        margin-top: -0.15em;
        font-size: 8em;
      }

      .text {
        font-size: 2em;
      }

    }

    .file-manager-main-path-bar {
      padding: 0em 0.5em;
      border-bottom: 1px solid $file-manager-border-color;
      color: $file-manager-color2;
      display: flex;
      align-items: center;


      .path {
        padding: 0.5em 0.5em;
        margin: 0 0.3em;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }

        &:hover {
          background-color: $file-manager-color-highlight-light;
        }

      }

      .path-to {
        color: darkgray;
      }

      .background-loading {
        opacity: 0;
        transition: 0.4s opacity ease-in-out;

        &.show {
          opacity: 1;
        }
        
        animation: background-loading-animation 2s infinite linear;
      }
      @keyframes background-loading-animation {
        from {
            transform: rotate(0deg);
        }
        to { 
            transform: rotate(360deg);
        }
    }
    }

    .file-manager-main-function-bar {
      border-bottom: 1px solid $file-manager-border-color;
      & > ul {
        display: flex;
        padding: 0;
        & > li {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }


    .file-manager-main-file-table-body-table {
      flex: 1;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 10px;
      }
      padding-bottom: 3em;  // Reserve space to upload-dialog when scrolled to bottom
      position: relative;
      user-select: none;
    }
    
    .file-manager-main-file-table {
      width: 100%;



      th, td {
        &.name {
          width: 65%;
          max-width: 426px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &.size {
          width: 12%;
          text-align: right;
        }
        &.modified {
          width: 23%;
          text-align: right;
        } 
      }

      thead {
        th {
          border-bottom: 1px solid $file-manager-border-color2;
          padding: 0.5em 1em;
          font-weight: normal;
          font-size: 0.95em;
          border-right: 1px solid $file-manager-border-color2;
          cursor: pointer;

          & > div {
            display: flex;
            align-items: center;
          }

          .icon {
            margin-left: auto;
          }

          &:hover {
            background-color: $file-manager-color-highlight-grey;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
      tbody {
        tr {
          &:hover {
            background-color: $file-manager-color-highlight-light;
          }
          &.selected {
            background-color: $file-manager-color-highlight;
          }

          &.mime-not-allowed {
            color: rgba(desaturate($file-manager-color, 100%), 0.4);
            
            &:hover {
              background-color: desaturate($file-manager-color-highlight-light, 100%);
            }
            &.selected {
              background-color: desaturate($file-manager-color-highlight, 100%);
            }
          }

          td {
            font-size: 0.95em;
            padding: 0.6em 1em;
            white-space: nowrap;
  
            &.name {
              .icon {
                margin-right: 1em;
              }
            }
            &.size {
            }
            &.modified {
              padding-right: 1em;
            }
          }
        }
      }

      &[data-view-mode=icon] {
        display: block;
        width: 100%;
        & > tbody {
          width: 100%;
          padding: 0.6em 0.6em;
          display: grid;
          grid-gap: 0;
          grid-template-columns: repeat(auto-fill,minmax(220px,1fr));
          & > tr {
            // width: calc(33.33333% - 0.5em * 2);
            position: relative;
            max-width: none;
            display: block;
            border: 1px solid $file-manager-border-color;
            border-radius: 9px;
            margin: 0.4em 0.5em;
            & > td {
              display: block;
              &.preview {
                padding: 0;
                margin: 0;
                position: relative;
                &::before {
                  content: ' ';
                  display: block;
                  width: 100%;
                  padding-top:70%;
                }
                & > .content {
                  border-radius: 9px 9px 0 0;
                  width: 100%;
                  position: absolute;
                  background-color: white;
                  top: 0;
                  left: 0;
                  overflow: hidden;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  padding: 0.8em 0.5em;

                  
                  .image {
                    max-width: 100%;
                    max-height: 100%;
                  }

                  .icon {
                    font-size: 6em;
                    color: lightgrey;
                  }
                }
              }
              &.name {
                padding: 0.9em 1.2em;
                width: 100%;
              }
              &.size, &.modified {
                display: none;
              }
            }
          }
        }
      }
    }


    // .file-manager-main-file-grid-container {
    //   margin: 0.75em 1em;
    //   .file-manager-main-file-grid {
    //     margin: 0;
    //     .file-manager-main-file-grid-item {
    //       padding: 0 0.5em;

    //       .item {
    //         margin: 0.5em 0em;
    //         border: 1px solid $file-manager-border-color;
    //         border-radius: 9px;

    //         &:hover {
    //           background-color: $file-manager-color-highlight-light;
    //         }
    //         &.selected {
    //           background-color: $file-manager-color-highlight;
    //         }
  
    //         &.mime-not-allowed {
    //           color: rgba(desaturate($file-manager-color, 100%), 0.4);
              
    //           &:hover {
    //             background-color: desaturate($file-manager-color-highlight-light, 100%);
    //           }
    //           &.selected {
    //             background-color: desaturate($file-manager-color-highlight, 100%);
    //           }
    //         }
            

    //         display: flex;
    //           align-items: center;
    //           padding: 0.8em 1.2em;
    //           .file-preview {
    //             // height: 8em;
    //             font-size: 1.2em;
    //           }
    //           .filename {
    //             margin-left: 1em;
    //             white-space: nowrap;
    //             overflow: hidden;
    //             text-overflow: ellipsis;
    //           }

    //         // width: 8em;
    //         .file-preview {
    //           // height: 8em;
    //         }
    //         .filename {
              
    //         }
    //       }
          
    //     }
    //   }
    // }

  }

  .context-menu {
    position: absolute;
    background-color: white;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    border: 1px solid $file-manager-border-color;
    width: 150px;
    // transition: 0.3s opacity;
    &.active {
      pointer-events: all;
      opacity: 1;
    }
  }


  .upload-dialog {
    user-select:text;
    width: 280px;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    font-size: 0.9em;

    &.open {
      .upload-dialog-content {
        height: 160px;
      }
    }

    .upload-dialog-bar {
      flex: 0;
      background-color: $file-manager-color;
      color: white;
      padding: 0.6em 1em; 
      border: 1px solid $file-manager-color;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s all;
      
      &:hover {
        background-color: $file-manager-color2;
      }

      .icon {
        margin-right: .9em;
      }
      .maximize-icon {
        cursor: pointer;
        margin-left: auto;
        &:hover {
          opacity: 0.7;
        }
      }
    }

    .upload-dialog-content {
      background-color: white;
      padding: 0; 
      border: 1px solid $file-manager-color;
      border-top: none;
      
      height: 0px;
      overflow-y: auto;
      overflow-x: hidden;

      padding: 0;

      transition: 0.5s all ease-in-out;

      .upload-file {
        display: flex;
        width: 100%;
        align-items: center;
        overflow-y: hidden;
        height: 54px;
        transition: 0.3s all ease-in-out;

        &.hidden {
          height: 0px;
          border-bottom: 0px;
        }

        border-bottom: 1px solid $file-manager-border-color2;

        &:hover {
          background-color: $file-manager-color-highlight-grey;
        }

        .upload-file-loading {
          display: flex;
          align-items: center;
          margin-left: 1em;
          min-width: 1.2em;
        }

        .upload-file-info {
          overflow: hidden;
          padding: 0.6em 0.6em 0.6em 1em;
          .name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .size {
            font-size: 0.88em;
            color: grey;
          }
        }
        .upload-file-cancel {
          cursor: pointer;
          flex: 0;
          margin-left: auto;
          font-size: 1.2em;
          padding: 0 0.6em 0 0em;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    
  }

}



.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  &.lds-ring-sm {
    width: 16px;
    height: 16px;

    & > div {
      width: 16px;
      height: 16px;
      border-width: 2px;
      margin: 0px;
    }
  }

  & > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 4px solid $file-manager-color;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $file-manager-color transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}








