

.entity-selector-modal-backdrop {
  z-index: 1010 !important;
}

.entity-selector-modal {
  z-index: 1015 !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-dialog {
    max-width: 92%;
    width: 100vw;
    margin: 0;

    .modal-content {
      max-width: 100%;

      .modal-body {
        height: 100%;
        // overflow-x: auto;

        & > div {
          height: 100%;
          /* height: calc(100% - 1.75rem * 2 - 50px); */
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }
    }
  }
}



.entity-selector {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 0.6rem;
  .btn {
    width: fit-content;
  }
  .preview {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  
  
  
  }
  
}
