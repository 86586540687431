@import "~bootstrap/scss/bootstrap";
@import "../responsive-mixins.scss";
@import "./variables.scss";

.entity-data-loading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.entity-data {
  display: flex;
  flex-direction: column;
  gap: 1em 0;
  flex-flow: row wrap;
  transition: 0.3s max-width;
  max-width: 100%;
  &.tight {
    gap: 0.3em 0;
  }

  &.thin {
    max-width: 768px;
  }

  &.wide {
    max-width: 992px;
  }

  .floating-save-btn-area {
    display: flex;
    align-items: center;
    justify-content: end;
    position: fixed;
    left: 0;
    /* right: 0; */
    width: 100%;
    text-align: right;
    /* padding-right: 1rem; */
    /* padding-bottom: 1rem; */
    padding: 1rem 1rem 1rem 1rem;
    bottom: 0;
    background: white;
    border: 1px solid #eaeaea;
    border-radius: 0;
    box-shadow: 0 -1px 3px 0 rgba(0,0,0,.1);
    opacity: 1;
    z-index: 20;

    transition: opacity 0.3s, bottom 0.3s;

    &.hidden {
      opacity: 0;
    }
  }

  @include mobile-view() {
    &:not(.no-margin-compensate) {
      @include desktop-view {
        margin-left: -0.6em;
        margin-right: -0.6em;
      }

      @include mobile-view {
        padding: 0 0.5rem 2rem 0.5rem;
      }

    }

  }

  & > * {
    width: 100%;

    // CSS order matters, so can't put media query in the same for-loop
    @for $i from 1 through 12 {
      &.div-width-#{$i} {
        width: percentage($i / 12);
      }
    }

    @for $i from 1 through 12 {
      @include media-breakpoint-up(sm) { 
        &.div-width-#{$i}-sm {
          width: percentage($i / 12);
        }
      }
    }

    @for $i from 1 through 12 {
      @include media-breakpoint-up(md) { 
        &.div-width-#{$i}-md {
          width: percentage($i / 12);
        }
      }
    }

    @for $i from 1 through 12 {
      @include media-breakpoint-up(lg) { 
        &.div-width-#{$i}-lg {
          width: percentage($i / 12);
        }
      }
    }

    @for $i from 1 through 12 {
      @include media-breakpoint-up(xl) { 
        &.div-width-#{$i}-xl {
          width: percentage($i / 12);
        }
      }
    }

    @for $i from 1 through 12 {
      @include media-breakpoint-up(xxl) { 
        &.div-width-#{$i}-xxl {
          width: percentage($i / 12);
        }
      }
    }


    &.hr {
      height: 0;
      border-bottom: 1px solid lightgrey;
      margin: 0 -0.3em 0.9em -0.3em;
      width: calc(100% + 0.6em);

      @include mobile-view {
        margin-left: 0;
        margin-right: 0;
      }
    }
  
    &.entity-data-item {
  
      display: flex;
      flex-direction: column;
      gap: 0.5em 0;
      margin: 0.3em 0;
      position: relative;
      // background-color: $admin-color-toggle;
      
      padding: 0 0.5rem;
      .entity-data-item-key {
        font-size: 1em;
        align-items: center;
  
        & > .title {
          font-weight: 600;
          overflow-wrap: break-word;

          &.required::after {
            content: "*";
            padding-left: 0.3em;
            color: red;
          }
        }
      }
  
      .entity-data-item-value {
        min-height: 38px;
        display: flex;
        align-items: center;
  
        input, textarea {
          font-size: 1em;
        }
      }
    }
    
    &.section {
      padding-top: 0.5em;
      padding-bottom: 1rem;
      margin-bottom: -1rem;
    }
  }
  
 
}