@import "./variables.scss";
@import "../scrollbar.scss";
@import "../responsive-mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@300;400&family=Noto+Serif+HK:wght@300;400&display=swap');



$admin-background-color: #f2f4f6;

$sidebar-width: 220px;
$sidebar-width-collapsed: 54px;

body {
  height: 100%;
  
  background-color: $admin-background-color !important;
}



.hr {
  height: 0;
  border-bottom: 1px solid lightgrey;
  margin: 0 0 .6em 0;
}

.admin {
  display: flex;
  flex-wrap: nowrap;
  // overflow-x: auto;
  // overflow-y: auto;
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  overscroll-behavior: contain;
  width: 100%;
  @include mobile-view { 
    width: calc(100% + #{$sidebar-width});
  }
  transition: 0.3s width ease-in-out;
  
  .card {
    border: 1px solid #eaeaea;
    border-radius: 0;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
    
  }

  .overflow-card {
    flex: 1;
    width: 100%;
    position: relative;
    overflow-y: overlay;
    overflow-x: auto;
    & > * {
      position: absolute;
    }
  }

  .admin-sidebar {
    
    position: fixed;
    height: 100%;
    z-index: 999;
    box-shadow: 0px 0px 15px rgba(100, 100, 100, 70%);
    width: $sidebar-width;
    transition: 0.3s width ease-in-out, 0.3s opacity ease-in-out, 0.3s margin-left ease-in-out;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background-color: $admin-color-primary;
    color: $admin-color-toggle;
    opacity: 1;


    // &:hover {
    //   .admin-siderbar-minifier {
    //     padding-right: 1em !important;
    //     opacity: 0.7 !important;
    //   }
    // }




    .admin-sidebar-header {
      flex-grow: 0;
      color: $admin-color-toggle;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 1em 0em;

      .admin-sidebar-title {
        transition: 0.3s all;
        white-space: nowrap;
      }

      
    }
    
    //#273441
    .admin-sidebar-logo {
      transition: 0.3s all, 0.5s opacity ease-in-out;
      width: 36px;
      margin: 0 0.6em 0 0.9em;
      padding: 0.2em;
      filter: drop-shadow(0px 0px 2px rgba(200,220,245, 0.3));
      animation-iteration-count: 0;

      & > img {
        width: 26px;
      }

      &.loading {
        animation: logo-loading 5s infinite ease-in-out;
      }
    }

    @keyframes logo-loading {
      from { opacity: 1.0; }
      20% { opacity: 0.2; }
      50% { opacity: 0.7; }
      80% { opacity: 0.2; }
      to { opacity: 1.0; }
    }

    
    hr {
      padding: 0;
      margin: 0;
    }

    .nav {
      
      flex-grow: 1;
      flex-wrap: nowrap;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0.3em 0;
      position: relative;

      transition: color .3s ease;
      // Apple-style scrollbar
      /* total width */




      .nav-item {
        font-size: 0.9em;
        .nav-link {
          transition: 0.3s all;
          color: $admin-color-toggle;
          &.active {
            background-color: $admin-color-highlight;
            color: $admin-color-highlight4;
          }
          &:hover {
            background-color: $admin-color-highlight2;
          }
          &:active {
            background-color: $admin-color-highlight3;
          }

          &.active {
            .nav-item-icon {
              color: $admin-color-highlight4;
            }
          }

          display: flex;
          align-items: center;
          border-radius: 0;
          padding: 0.7rem 1rem;
          .nav-item-icon {
            transform: translateX(-1px);
            transition: 0.3s all;
            font-size: 1.2em;
            line-height: 1em;
            min-width: 2.2em;
            text-align: center;
            padding: 0;
            padding-right: 0.6em;
          }
          .nav-item-title {
            transition: 0.3s all;
            white-space: nowrap;
          }
        }
      }
    }

    // .admin-siderbar-minifier {
    //   position: absolute;
    //   right: 0;
    //   bottom: 0.7em;
    //   cursor: pointer;
    //   transition: 0.3s all ease;
    //   opacity: 0.3;
    //   color: $admin-color-toggle;
    //   background-color: $admin-color-highlight2;
    //   font-size: 0.8em;
    //   margin: 0;
    //   padding: 0.4em .8em 0.4em 0.9em;
    //   border-radius: 20px 0 0 20px;

    //   svg {
    //     transition: 0.5s all ease;
    //   }

    //   &:hover {
    //     opacity: 0.5;
    //     background-color: $admin-color-highlight3;
    //     padding-right: 1.3em !important;
    //   }
    // }
    

    .footer {
      flex-grow: 0;
      padding: 1em 0em;
      .admin-user {
        cursor: pointer;
        transition: 0.3s all;
        display: flex;
        align-items: center;
        color: $admin-color-toggle;
        text-decoration: none;
        padding-left: 0.3em;
        padding-right: 1.2em;
        outline: none;
        
        .admin-user-icon {
          transform: translateX(-1px);
          font-size: 1.5em;
          line-height: 1em;
          margin: 0 0.5em;
        }

        .admin-user-display-name {
          transition: 0.3s all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.3em;
        }

        .admin-user-caret {
          position: relative;
          transition: 0.3s all;
          transform-origin: center;
        }

        &.show {
          .admin-user-caret {
            transform: rotate(-180deg);
          }
        }
      }

      .dropdown-menu {
        display: block;
        font-size: .92em;
        background-color: $admin-color-highlight;
        
        display: inherit;
        // transform: translate(69px, 19px) scaleX(0);
        transition: all 0.3s;
        // transform: translate3d(0px, -43px, 0px);
        inset: auto auto 0px 0px;
        margin: 0px;
        transform: translate3d(0px, -26px, 0px);
        opacity: 0;
        pointer-events: none;
        width: $sidebar-width;
        &.show {
          display: inherit;
          pointer-events: all;
          opacity: 1;
          // transform: translate(69px, 19px) scaleX(1);
          transform: translate3d(0px, -56px, 0px) !important;
        }

        .dropdown-item {
          padding: .5em 1.4em;
          border: none;
          &:hover {
            background-color: $admin-color-highlight2;
          }
          &:active {
            background-color: $admin-color-highlight3;
          }
        }
      }
    }
    
  }

  .admin-container {
    display: flex;
    flex-direction: column;
    transition: 0.3s margin-left ease-in-out;
    padding: $admin-container-padding-top $admin-container-padding-x $admin-container-padding-bottom $admin-container-padding-x;
    min-width: fit-content;
    // width: fit-content;
    // min-width: 768px;
    // overflow-x: hidden;
    margin-left: $sidebar-width;
    font-size: .9em;

    
    @include mobile-view {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .admin-container-dark {
      background-color: $admin-color-dark;
      color: $admin-color-toggle;

    }

    &[data-name='system-config'] {
      overflow-x: hidden;
    }

    &[data-name='not-found'] {
      display: flex;
      width: 100vw;
      height: 100vh;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
    }

    hr {
      background-color: black;
    }

    .admin-container-title-bar {
      font-size: 1.3rem;
      font-weight: 600;
      padding: ($admin-container-padding-top + 0.3rem) $admin-container-padding-x .8em $admin-container-padding-x;
      margin: (-$admin-container-padding-top) (-$admin-container-padding-x) 0 (-$admin-container-padding-x);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.6em 0.3em;

      @include mobile-view {
        padding: 0.6rem 1rem;
        margin-left: 0;
        margin-right: 0;
      }

      // background-color: $admin-color-highlight;

      & > .admin-container-title {
        display: flex;
        align-items: center;
        .back-btn {
          color: darkgrey;
        }
        .sidebar-toggle-btn {
          color: darkgrey;
          margin-right: .8rem;
          cursor: pointer;
          transition: 0.3s color;
          &:hover {
            color: $primary;
          }
        }

        .admin-container-title-icon {
          text-align: center;
          width: 1.25em;
          margin-right: .9rem;
          margin-left: 0.5rem;
          font-size:0.9em;
        }
      }
    }
  }

  &[data-sidebar-collapsed='true'] {
    width: 100%;
    .admin-sidebar {
      width: $sidebar-width-collapsed;
      
      // position: fixed;
      // left: 0;

      @include mobile-view {
        overflow-x: hidden;
        opacity: 0;
        pointer-events: none;
        width: $sidebar-width;
        margin-left: -$sidebar-width;
      }
    }

    .admin-sidebar-logo {
      margin-left: 0.68em !important;
    }

    .admin-sidebar-title {
      pointer-events: none;
      opacity: 0;
    }

    // .admin-siderbar-minifier {
    //   //opacity: 0 !important;
    //   //pointer-events: none;
    //   svg {
    //     transform: rotate(180deg);
    //   }
    // }

    .nav-item-title {
      opacity: 0;
    }

    .nav-link {
      padding-left: 0 !important;
      padding-right: 0 !important;
      min-width: $sidebar-width-collapsed;
    }
    
    .nav-item-icon {
      padding-right: 0 !important;

      min-width: $sidebar-width-collapsed !important;
    }

    .nav-item-tooltip {
      padding: 0.4em 0.6em;
    }



    .admin-user {
      padding-left: 0.19em !important;

      .admin-user-display-name {
        opacity: 0 !important;
        text-overflow: clip;
        pointer-events: none;
      }

      .admin-user-caret {
        opacity: 0 !important;
        pointer-events: none;
      }
    }

    .admin-container {
      margin-left: $sidebar-width-collapsed;
      @include mobile-view {
        margin-left: 0;
      }
    }
  }


}
.admin-login {
  display: flex; 
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  overscroll-behavior: contain;

  background-color: $admin-color-primary;
  height: 100%; /* Fallback for browsers that do not support Custom Properties */
  // height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;




}

.json-editor-error-line {
  position:absolute;
  background-color: #660000;
}