

.file-manager-modal-backdrop {
  z-index: 1020 !important;    // File previewer: 1030, File property: 1040, Glboal modal 1050, 
}

.file-manager-modal {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1025 !important;    // File previewer: 1035, File property: 1045, Glboal modal 1055

  .modal-dialog {
    max-width: 92%;
    width: 100vw;
    margin: 0;

    .modal-content {
      max-width: 100%;

      .modal-body {
        height: calc(90vh - 1.75rem * 2 - 50px);
        overflow-x: auto;
      }
    }
  }
}