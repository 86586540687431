$primary: #14304f;
$secondary: #27b827;
$danger: #9e2020;
$warning: #ebb647;
$success: #1d774d;
$info: #154172;
// $secondary: #6ed447;

$white: white;
$black: $primary;
$color-contrast-dark: white;
$color-contrast-light: $primary;
$min-contrast-ratio: 4.5;