

.publishment-contacts {
  grid-gap: 0.5em 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(320px,1fr));
  & > .publishment-contact {
    background-color: white;
    border: 1px solid lightgrey;
    display: flex;
    padding: 0.5em 0.5em;
    gap: 0.5em;
    position: relative;
    & > .close-btn {
      cursor: pointer;
      color: darkgrey;
      position: absolute;
      right: 0.1em;
      top: 0;
      padding: 0.3em;
      font-size: 1.2em;

      &:hover {
        color: lightgrey;
      }

    }

    & > .left {
      padding: 0em 0.5em;
      & > .image {
        width: 64px;
        height: 64px;
        &.image-placeholder {

        }
      }
    }

    & > .right {
      & > .name {
        font-weight: bold;
        font-size: 1.1em;
      }
      & > .alt-name {
        font-size: 0.95em;
      }
      & > .license {
        font-size: 0.88em;
        color: darkgrey;
      }

      & > .contact-methods {
        margin-top: 0.5em;
        display: flex;
        gap: 0.5em;
        & > .contact-method {
          cursor: pointer;
          & > .image-icon {
            width: 32px;
            height: 32px;
          }

          &.email, &.phone, &.whatsapp {
            color: white;
            font-size: 1.3em;
            padding: 0.2em 0.3em;
            border-radius: 0.4em;
            &.phone {
              background-color: #006d68;
            }
            &.whatsapp {
              background-color: #25D366;
            }
            &.email {
              background-color: #002f7a;
            }
          }
        }
      }
    }
  }
}


