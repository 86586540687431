
.property-publishment-images-preview-modal-backdrop {
  z-index: 1030 !important;    // File manager: 1020, File property: 1040, Glboal modal 1050,
  
}

.property-publishment-images-preview-modal {

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1035;    // File manager: 1025, File property: 1045, Glboal modal 1055
  .modal-dialog {
    max-width: 90%;
  }
  .modal-dialog {

    .modal-header {
      .filename {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .modal-body {
      text-align: center;
      .image {
        // margin: 1.2em 0.3em;
        max-height: 64vh;
        max-width: 100%;
      }
    }
  }
}
.property-publishment-images-preview {
  
  display: flex;
  gap: 1.5em 2em;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .empty {
    font-size: 1.2em;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item {
    position: relative;
    &.dragging {
      opacity: 0.7;
    }
    .delete-btn {
      position: absolute;
      top: -10px;
      right: -10px;
    }
    .key {
      position: absolute;
      top: -10px;
      left: -6px;
    }
    .image {
      max-width: 180px;
      max-height: 120px;
      object-fit: contain;
      // background-color: grey;
      border: 1px solid lightgrey;

      &.temp-transparent {
        position: absolute;
        opacity: 0;
        pointer-events: none;
      }
      &.temp {
        width: 180px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        
      }
    }
  }
}