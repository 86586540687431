
.sub-page-container {
  position: relative;

  .sub-page {
    width: 100%;
    position: absolute;
    top: 0;
    transition: 1s all;

    .hr {
      margin: 1.6em 0 1em 0;
    }

    &:not(.active) {
    }

    // Active elements
    &.active {

    }

    // Previous element
    &:not(.active) {
      transform: translateX(-100%);
      opacity: 0;
    }

    // Next elements
    &.active ~ * {
      transform: translateX(100%) !important;
    }

  }
}