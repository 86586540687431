@import "./variables.scss";

.building-and-property-image-tool {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  position: relative;

  &.dragging {
    outline: 6px $admin-color-highlight2 dashed;

    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($admin-color-highlight4, 0.5);
      z-index: 99;
      content: " ";
    }
  }
  
  .building-and-property-image-tool-table {
    background-color: white;
    min-height: 420px;
    height: 60vh;
    overflow: auto;
    width: 100%;
    position: relative;
    box-shadow: 2px 2px 7px rgba(grey, 0.7);
    margin: 0.3rem 0;
    & > * {
      padding: 0 0rem 3rem 0rem;
      position: absolute;
    }


    .preview-zoom-btns {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      & > * {
        cursor: pointer;
      }
    }

    .building-and-property-image-tool-preview-image {
      overflow: hidden;
      cursor: pointer;
      text-align: center;
      width: 100%;
      height: 6rem;

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &[data-size='1'] {
        height: 2rem;
      }
      &[data-size='3'] {
        height: 11rem;
      }
      &[data-size='4'] {
        height: 16rem;
      }

      & > img {
        // width: 100%;
        height: 100%;
        object-fit: contain;
      }

      & > .loader {
        width: 2rem;
        height: 2rem;
      }
    }
  }



}

.building-and-property-image-tool-preview-backdrop {
  z-index: 1030 !important;    // File manager: 1020, File property: 1040, Glboal modal 1050, 
}

.building-and-property-image-tool-preview-backdrop-front {
  z-index: 1036 !important;    // File manager: 1020, File property: 1040, Glboal modal 1050, 
}

.building-and-property-image-tool-preview, .building-and-property-image-tool-preview-front {
  z-index: 1035 !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.building-and-property-image-tool-preview-front {
    z-index: 1037 !important;
  }

  .modal-dialog {
    max-width: 92%;
    width: 100vw;
    margin: 0;

    .modal-content {
      max-width: 100%;
      // height: fit-content !important;
      .modal-body {    
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;

      }
    }
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 100%;
  }

  .loader {
    margin: 8rem 2rem;
    width: 4rem;
    height: 4rem;
  }
}