
@import "variables.scss";
@import "~bootstrap/scss/bootstrap";

// @import "page-main.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, div#root {
  height: 100%;
}

button, .btn {
  transition: 0.3s all;
}

.modal {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .modal-dialog {
    // height: 100%;
    /* padding-top: 0; */
    /* padding-bottom: 0; */

    .modal-content {
      height: 100%;
      // height: calc(100% - 1rem);
      // overflow-y: overlay;
      // height: calc(100% - 2rem);

      .modal-body {
        overflow: auto;
      }
    }
  }
  
}

.dropdown {
  & > .dropdown-menu {
    font-size: 0.9rem;
    & > .dropdown-item {
      cursor: pointer;
      padding: 0.3rem 1rem;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.app-mode {
  z-index:9999;
  font-size: 0.9em;
  position: fixed;
  top: 0.1em;
  right: 0.3em;
  font-weight: bold;
  animation: blinker 2s infinite linear;
  color: rgba(100, 100, 100, 0.8);
  pointer-events: none;
}

@keyframes blinker {
  from { opacity: 1.0; }
  50% { opacity: 0.3; }
  to { opacity: 1.0; }
}


.appointment-info-item.row.pb-4 {
  padding-top: 20px;
}
// .dev-text {
//   position: absolute;
//   top: 0;
//   right: 0;
//   opacity: 0.5;
//   color: black;
//   margin: 0.2em 0.5em;
//   font-size: 0.9em;
//   text-transform: capitalize;
// }

.margin-top-50 {
  margin-top: 50px;
}

.confirmation_icon {
  width: 178px;
  height: 136px;
}

span.text-xl.text-default.fontInter.fw-normal {
  margin-top: 2px;
}
.color-blue {
  color: $primary !important;
}

.ml-27 {
  margin-left: 27px;
}
.empty_cart {
  width: 300px;
  height: 300px;
}

.empty_personalinfo{
  width: 360px;
}