
@import "./variables.scss";

.global-selector {
  background-color: rgba(white, 0.85);
  backdrop-filter: blur(0px);
  position: fixed;
  width: 100%;
  height: 100%;

  left: 0;
  top: 0;
  z-index: 1000;


  opacity: 1;
  // pointer-events: none;
  // backdrop-filter: blur(3px);

  transition: 0.3s all;

  &.open {
    // opacity: 1;
    // pointer-events: all;
  }

  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .close-btn {
      color: $primary;
      cursor: pointer;
      font-size: 1.6em;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      padding: 0.2em;
      margin: 0.2em;
    }

    .global-selector-main {
      margin: 3.5em 0.3em;
      padding: 0.5em 0em;

      max-height: 100%;
      overflow-y: auto;

      .title {
        margin: 0.2em 3em 0.6em 3em;
      }
    
      & > ul {
        padding: 0;
        margin: 0.3em 0em;
        & > li {
          text-align: center;
          list-style-type: none;
          color: $primary;

          &.selected {
            font-weight: bold;
          }

          & > * {
            cursor: pointer;
            width: 100%;
            display: inline-block;
            color: $info;
            text-decoration: none;
            padding: 0.4em 2em; 

            &:hover {
              opacity: 0.8;
              background-color: rgba(white, 0.5);
            }
    

          }
        }
      }
    }
  }
}